import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

export default class Footer extends Component {
  render() {
    const { dados } = this.props;

    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 12000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      pauseOnDotsHover: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            adaptiveHeight: true
          }
        }
      ]
    }

    return (
      <footer className="footer">
        <div className="footer_texto">
          <Slider {...settings}>
            {
              dados.textos.map((item, key) => {
                return (
                  <div key={item.titulo} className="footer_texto--slider">
                    <h2>{item.titulo}</h2>
                    <p>{item.conteudo}</p>
                  </div>
                )
              })
            }
          </Slider>
        </div>
      </footer>
    )
  }
}