import React, { Component } from 'react';
import BackgroundSlider from 'react-background-slider';

export default class Background extends Component {
  render() {
    const { dados } = this.props;

    const images = dados.banners.map(item => {
      return item.imagem
    })

    return (
      <div>
        <BackgroundSlider
          images={images}
          duration={12}
          transition={2}
        />
        <div className="maskBanner"></div>
      </div>
    )
  }
}