import React, { Component } from 'react';
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

import logo from './../../assets/logo-branco-v2.png';

export default class Header extends Component {
  renderLinkMail() {
    const { dados } = this.props;
    
    return "mailto:" + dados.email[0].email
  }

  render() {
    const { dados } = this.props;

    return (
      <header className="header">
        <div className="header_logo">
          <h1>
            Camile Sambaquy Franzoi | Arquitetura e Interiores
            <img src={logo} alt="Camile Franzoi - Arquitetura e Interiores" />
          </h1>
        </div>

        <div className="header_contact">
          <ul>
            <li><FaPhoneAlt size={18} color="#F4F4F4" /> {dados.telefone[0].phone}</li>
            <li><FaEnvelope size={18} color="#F4F4F4" /> <a href={this.renderLinkMail()}>{dados.email[0].email}</a></li>
          </ul>
        </div>
      </header>
    )
  }
}