import React, { Component } from 'react';

import Background from './components/Background';
import Header from './components/Header';
import Footer from './components/Footer';

import dados from './dados.json';

export default class App extends Component {
  state = {
    dados: null
  }

  componentDidMount() {
    fetch('https://cors-anywhere.herokuapp.com/http://www.camilefranzoi.com/dados.json')
      .then(response => response.json())
      .then(dados => this.setState({ dados }))
      .catch(err => {
        this.setState({
          dados: dados
        })
      })
  }

  render() {
    if (!this.state.dados) {
      return null
    }

    return (
      <div>
        <Background dados={this.state.dados} />
        <Header dados={this.state.dados} />
        <Footer dados={this.state.dados} />
      </div>
    )
  }
}
